<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('article.title')"
        :sub-title="$t('article.desc')"
        :btn-title="$t('article.new_btn')"
        route-name="addArticle"
    />

    <div>
      <filter-header
          :hide-filter="true"
          @applyFilter="applyFilter"
          @orderApplicants="orderCurrencies"
      />
    </div>

    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <div class="w-table d-flex flex-column settings-articles">
            <div class=" settings-articles-grid">
              <div
                  v-for="article in getAllArticles"
                  :key="article.id"
                  class="setting-article-card"
              >
                <article-card :article="article" />
              </div>
            </div>
            <wameed-no-data
                v-if="getAllArticles && getAllArticles.length < 1"
                icon="nodata-icon"
                :title="$t('packages.no_data')"
                :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <wameed-pagination
              v-if="getTotalArticles"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalArticles.totalItems"
              :per_page="filterData.per_page"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';
import ArticleCard from "@/views/pages/articles/components/ArticleCard";

export default {
  components: {
    ArticleCard,
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      filterData: {
        page: 1,
        per_page: 8,
        order_by: 'name',
        search: '',
      },

    };
  },
  computed: {
    ...mapGetters({
      getAllArticles: 'getAllArticles',
      getTotalArticles: 'getTotalArticles',
    }),
  },

  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadArticles: 'loadArticles',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadArticles(this.filterData);
    },
    applyFilter() {
      this.content.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },

    orderCurrencies(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },

  },
};
</script>
