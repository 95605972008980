<template>
  <div  class="article-card">
    <div class="article-card-header">
      <div class="article-card-edit" @click="edit">
        <edit-icon/>
      </div>
      <div class="overlay"></div>

      <img :src="article.image" alt="">

    </div>
    <div class="article-card-footer">
      <div class="date">
        <calendar-small-icon/>
        <time class="text-light-10">{{ moment(article.created_at).format('ll') }}</time>

      </div>
      <p class="title">{{ article.title }}</p>
      <p class="desc">
        {{ article.excerpt }}
      </p>
      <div class="link mt-2 " @click="goToDetail">
        {{ $t("common.read-more") }}
        <double-arrow-icon class="rotate-180"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    article: {
      type: Object,
      required: true
    },
  },

  methods: {
    goToDetail() {
      this.$router.push({
        name: 'articleDetail',
        params: { lang: this.$i18n.locale, id: this.article.id },
      });
    },

    edit() {
      this.$router.push({
        name: 'updateArticle',
        params: { lang: this.$i18n.locale, id: this.article.id },
      });
    },
  },

}
</script>
